export const PWA_MODES = ['fullscreen', 'standalone', 'minimal-ui', 'browser'];

export function isInPwaMode(): boolean {
	return (
		window.matchMedia('(display-mode: standalone)').matches || // standard
		!!('standalone' in window.navigator && window.navigator.standalone) || // iOS - safari
		document.referrer.includes('android-app://') || // android
		import.meta.env.VITE_PWA_DEV_MODE === 'true' // dev mode
	);
}
