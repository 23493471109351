import {
	initActiveTimeSeriesStore,
	initGlobalFeedItemListStore,
	initIDBStore,
	initIsDesktopSidebarExpanded,
	initIsExtensionStore,
	initIsMobileStore,
	initIsPwaStore,
	initIsWebshareSupportedStore,
	initRtpWebsocketClientStore,
	initSbStore,
	initSearchHistoryStore,
	initSettingsStore,
	initWatchlistStore
} from '.';
import { initIsMacintoshStore } from './isMacintosh';
import { initNotificationsWebsocketClientStore } from './notificationsWebsocketClient';

/**
 * Initializes all internal stores.
 *
 * This should be called at the top-level of the top-most layout component.
 *
 * @example
 *
 * ```
 * <script>
 *     import { initStores } from "@tickrr/ui/stores";
 *
 *    initStores();
 *
 *   // ...
 *   // Use stores here and in any components below.
 *   // ...
 * </script>
 * ```
 *
 */
export function initStores(pyServerWsUrl: string) {
	// Core stores first
	const sbStore = initSbStore();
	const settingsStore = initSettingsStore();

	// Then the rest
	const activeTimeSeriesStore = initActiveTimeSeriesStore();
	const feedItemListStore = initGlobalFeedItemListStore();
	const idbStore = initIDBStore();
	const isDesktopSidebarExpanded = initIsDesktopSidebarExpanded();
	const isExtensionStore = initIsExtensionStore();
	const isMacintoshStore = initIsMacintoshStore();
	const isMobileStore = initIsMobileStore();
	const isPwaStore = initIsPwaStore();
	const isWebshareSupportedStore = initIsWebshareSupportedStore();
	const searchHistoryStore = initSearchHistoryStore();
	const watchlistStore = initWatchlistStore();
	const rtpWebsocketClientStore = initRtpWebsocketClientStore(pyServerWsUrl);
	const notificationsWebsocketClientStore = initNotificationsWebsocketClientStore(pyServerWsUrl);
	isMacintoshStore.set(false);

	return {
		activeTimeSeriesStore,
		feedItemListStore,
		idbStore,
		isDesktopSidebarExpanded,
		isExtensionStore,
		isMacintoshStore,
		isMobileStore,
		isPwaStore,
		isWebshareSupportedStore,
		notificationsWebsocketClientStore,
		rtpWebsocketClientStore,
		sbStore,
		searchHistoryStore,
		settingsStore,
		watchlistStore
	};
}
