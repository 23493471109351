/** Supabase Auth Helpers
 * See https://supabase.com/docs/guides/getting-started/tutorials/with-sveltekit#supabase-auth-helpers
 */
import type { Database } from '@tickrr/db/supabaseTypes';

import { browser } from '$app/environment';
import { PUBLIC_SUPABASE_ANON_KEY, PUBLIC_SUPABASE_URL } from '$env/static/public';
import { logger } from '$lib/logger';
import { defaultMetaTagInfo } from '$lib/metatagInfo';
import { createBrowserClient, isBrowser, parse } from '@supabase/ssr';
import { QueryClient } from '@tanstack/svelte-query';

import type { LayoutLoad } from './$types.ts';

/**
 * NOTE: If you add or remove data to either (1) here or (2) the root '+layout.ts' file,
 * the type of the $page store needs to be updated to reflected those changes in
 * './app.d.ts'.
 */
export const load: LayoutLoad = async ({ data, fetch, url }) => {
	// Init Supabase.
	// See https://supabase.com/docs/guides/auth/server-side/creating-a-client?framework=sveltekit
	logger.debug('Initializing Supabase client...');
	const supabase = createBrowserClient<Database>(PUBLIC_SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY, {
		cookies: {
			get(key) {
				if (!isBrowser()) {
					return JSON.stringify(data.session);
				}

				const cookie = parse(document.cookie);
				return cookie[key];
			}
		},
		global: {
			fetch
		}
	});

	// Init query client.
	// See https://tanstack.com/query/latest/docs/svelte/ssr.
	logger.debug('Initializing query client...');
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				enabled: browser,
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false
			}
		}
	});

	return {
		defaultMetaTags: defaultMetaTagInfo,
		queryClient,
		supabase,
		url: url,
		...data
		// We need to override the server session with the client session.
		// These two will not align when a user signs in and will cause the
		// user to need to reload the page to see the authenticated state.
		// This is the case be with with email + password, OAuth, etc.
		// session: freshestSession
	};
};
